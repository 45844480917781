export const dateFilter = (
  value: string,
  format: 'date' | 'datetime' = 'date'
) => {
  const options: {
    [key: string]: string;
  } = {};

  if (format.includes('date')) {
    options.day = '2-digit';
    options.month = 'long';
    options.year = 'numeric';
  }

  if (format.includes('time')) {
    options.hour = '2-digit';
    options.minute = '2-digit';
    options.second = '2-digit';
  }

  return new Intl.DateTimeFormat('ru-RU', options).format(new Date(value));
};
